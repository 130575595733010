// All the functions related to the interview page
let Interview = Barba.BaseView.extend({

    // Register the current namespace
    namespace : 'interview',

    // ---------------------------------------
    // DS Stuff
    // ---------------------------------------
    // Init the lazyload
    lazyload : function () {
        let lazyObject = new LazyLoad({ elements_selector : '.image-is-lazy' });
    },

    // Add attributes on links
    links : function () {
        let links = document.querySelectorAll('.main-content a, .interview-content a');
        if (links) {
            for (let link of links) {
                if ( location.hostname === link.hostname || !link.hostname.length ) { return }
                link.setAttribute('target' , '_blank');
            }
        }
    },


    // ---------------------------------------
    // Barba Events
    // ---------------------------------------
    onEnter : function ()
    {
        this.lazyload();     // Initialize the lazyload
        this.links();        // Links Attribute
    },

    // WHen the page is gone destroy all the listener
    onLeaveCompleted : function () {
    }
});