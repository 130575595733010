// @codekit-prepend "./views/home"
// @codekit-prepend "./views/space"
// @codekit-prepend "./views/note"
// @codekit-prepend "./views/interview"
// @codekit-prepend "./transition/fade"

// Initialize the views
Home.init();
Space.init();
Interview.init();
Note.init();

// Set up barba configurations
Barba.Pjax.Dom.containerClass = "ds-container";
Barba.Pjax.Dom.wrapperId      = "ds";

// Set the new custom transition
Barba.Pjax.getTransition = function() { return BarbaTransition; };

// Initialize barbajs
Barba.Pjax.start();
