// ALl the functions related to 
let Space = Barba.BaseView.extend({

    // Register the current namespace
    namespace : 'space',



    // ---------------------------------------
    // DS Stuff
    // ---------------------------------------
    projects : function () {

        // Grab the slider
        let projectsSlider = document.querySelector('.projects');

        // Check if we have a slider
        if (projectsSlider) {

            // Config Object
            let config = {
                imagesLoaded    : true,
                percentPosition : false,
                freeScroll      : true,
                lazyLoad        : 2,
                cellAlign       : 'left',
                contain         : true,
                pageDots        : false,
                arrowShape      : { 
                    x0: 35,
                    x1: 50, y1: 35,
                    x2: 55, y2: 35,
                    x3: 40
                }
            };

            // Start the slider
            let Slider = new Flickity ('.slider', config);
        }
    },

    // Init the lazyload
    lazyload : function () {
        let lazyObject = new LazyLoad({ elements_selector : '.image-is-lazy' });
    },

    // Add attributes on links
    links : function () {
        let links = document.querySelectorAll('.main-content a, .interview-content a');
        if (links) {
            for (let link of links) {
                if ( location.hostname === link.hostname || !link.hostname.length ) { return }
                link.setAttribute('target' , '_blank');
            }
        }
    },

    // ---------------------------------------
    // Barba Events
    // ---------------------------------------
    onEnter : function ()
    {
        this.projects();     // Init the slider when present
        this.lazyload();     // Initialize the lazyload
        this.links();        // Links Attribute
    },

    // WHen the page is gone destroy all the listener
    onLeaveCompleted : function () {
    }
});