
let Home = Barba.BaseView.extend({

    // Register the current namespace
    namespace : 'home',


    // ---------------------------------------
    // DS Stuff
    // ---------------------------------------
    intro : function () {

        // Grab the button and the section
        let homeIntroBtn     = document.querySelector('.intro-button');
        let homeIntroSection = document.querySelector('.intro-content-more');

        // Check if both items exist
        if (homeIntroBtn && homeIntroSection) {
            
            // if they exist, register a new function
            function showIntroContent () {
                homeIntroBtn.innerHTML = (homeIntroBtn.innerHTML == 'Read More') ? "Read Less" : "Read More";
                homeIntroSection.classList.toggle('is-open');
            };

            // Register the listener on the button
            homeIntroBtn.addEventListener('click' , showIntroContent);

        }
    },

    // Init the lazyload
    lazyload : function () {
        let lazyObject = new LazyLoad({ elements_selector : '.image-is-lazy' });
    },

    onEnter : function () {
        this.intro();
        this.lazyload();
    },


    // WHen the page is gone destroy all the listener
    onLeaveCompleted : function () {
    }
});